import { Button } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";
import Carousel, { Modal, ModalGateway as OriginalModelGateway } from "react-images";
import ReactPhotoGallery from "react-photo-gallery";

import { Divider, PageHeading, PageSection, SectionContainer, TrainingGroupCardProps, TrainingGroupCards } from '../Components/Components';

import CollectedImages from '../CollectedImages';

const ModalGateway = OriginalModelGateway as React.ComponentType<{ children?: React.ReactNode }>;

const trainingGroups: TrainingGroupCardProps[] = require('../JuniorsTrainingGroupsData.json');

function TrainingGroups() {
    return (
        <PageSection
            id="training-groups"
            title="Training Groups"
        >
            <TrainingGroupCards trainingGroups={trainingGroups} />
        </PageSection>
    );
}

function AboutJuniorsFootball() {
    return (
        <PageSection
            id="about-juniors-football"
            title="About Juniors Football"
        >
            <h3>What is Junior AFL?</h3>
            <p>
                This is the initial entry age of AFL competition but is still fun! For male and
                female kids aged between 7-12 years. Generally, the AFL Hunter Central Coast who
                manage the competition, set age groups at the odd age numbers. This means the
                club can enter teams in the following age groups if we have enough players
                registered to make a team:
            </p>
            <ul>
                <li>
                    <b>Under 9 years</b> (mixed boys and girls)
                </li>
                <li>
                    <b>Under 11 years</b> (can be a mixed team or single gender teams, such as an
                    U11 girls team and a U11 boys, i.e. 2 separate teams)
                </li>
                <li>
                    <b>Under 13 years</b> (can be a mixed team or single gender teams, such as an
                    U13 girls team and a U13 boys, i.e. 2 separate teams)
                </li>
            </ul>
            <h3>What is Youth AFL?</h3>
            <p>
                This competition is designed for teenagers regardless of whether they have
                played Auskick or Juniors. Aimed for male and female teenagers of 13-17 years of
                age. Generally, the AFL Hunter Central Coast who manage the competition, set age
                groups at the odd age numbers. This means the club can enter teams in the
                following age groups if we have enough players registered to make a team:
            </p>
            <ul>
                <li>
                    <b>Under 15 years</b> (girls)
                </li>
                <li>
                    <b>Under 15 years</b> (boys)
                </li>
                <li>
                    <b>Under 17 years</b> (girls)
                </li>
                <li>
                    <b>Under 17 years</b> (boys)
                </li>
            </ul>
            <br />
            <h3>What is minimum and max age for each age group?</h3>
            <p>
                The AFL NSW ACT Rules determine the minimum age by the following:
            </p>
            <table>
                <colgroup>
                    <col span={1} style={{ width: "30%" }} />
                    <col span={1} style={{ width: "40%" }} />
                    <col span={1} style={{ width: "30%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Age Group</th>
                        <th>
                            Minimum Age to Participate in Age Group (age as at 31 December of the year prior
                            to the Competition Year)
                        </th>
                        <th>Age Turning in the Competition Year</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Under 9</td>
                        <td>See *</td>
                        <td>See*, 7, 8, or 9</td>
                    </tr>
                    <tr>
                        <td>Under 11</td>
                        <td>8</td>
                        <td>9, 10, or 11</td>
                    </tr>
                    <tr>
                        <td>Under 13</td>
                        <td>10</td>
                        <td>11, 12, or 13</td>
                    </tr>
                    <tr>
                        <td>Under 15</td>
                        <td>12</td>
                        <td>13, 14, or 15</td>
                    </tr>
                    <tr>
                        <td>Under 17</td>
                        <td>14</td>
                        <td>15, 16, or 17</td>
                    </tr>
                </tbody>
            </table>
            <p>
                <i>
                    *Under 9 must be a minimum of seven (7) years of age by 31 July in the year of
                    the relevant season
                </i>
            </p>
            <h3>What does the junior program include?</h3>
            <h4>Training</h4>
            <p>
                Each week the teams will have their own training session that runs for 1 hour or
                1½ hours at Ferodale Oval, Medowie. The program is subject to change each season
                depending upon field, coach availability and team majority availability.
                Generally teams may train on a Monday, Tuesday, or Wedndesday night. We have
                held training for all teams on a Tuesday evening only in the past. It depends on
                what teams are entered.
            </p>
            <h4>Match Day</h4>
            <p>
                Each Sunday teams will play a match against another team in the competition.
                Teams can be entered from Hunter and Central Coast regions. Competitions may be
                Hunter only or a combined Hunter and Central Coast competition. Not all clubs
                field teams in every competition.
            </p>
            <h3>What are the Hunter Clubs?</h3>
            <p>
                Details of Hunter Junior Clubs can be found at <a
                    href="https://aflhuntercentralcoast.com.au/hunterclubs/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Hunter Junior AFL Clubs - AFL Hunter Central Coast
                </a>
            </p>
            <h3>What are the Central Coast Junior Clubs?</h3>
            <p>
                Details of the Central Coast Junior Clubs can be found at <a
                    href="https://aflhuntercentralcoast.com.au/ccoastclubs/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Central Coast Junior AFL Clubs - AFL Hunter Central Coast
                </a>
            </p>
            <h3>How long do the matches go for?</h3>
            <p>
                AFL is played in quarters. Quarter times depend upon the age group and
                competition type. There are allocated breaks at quarter-time, half-time and
                three-quarter-time. The most recent season match timings were:
            </p>
            <table>
                <colgroup>
                    <col span={1} style={{ width: "50%" }} />
                    <col span={1} style={{ width: "50%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Competition</th>
                        <th>Quarter Duration (x4 for total field time)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Under 9</td>
                        <td>12 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 11</td>
                        <td>15 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 13</td>
                        <td>15 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 15 Females</td>
                        <td>15 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 15 Males</td>
                        <td>20 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 17 Females</td>
                        <td>18 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Under 17 Males</td>
                        <td>20 minute quarters</td>
                    </tr>
                </tbody>
            </table>
            <h3>What kit is provided with registration?</h3>
            <p>To take the field a player needs:</p>
            <ul>
                <li>Club jersey</li>
                <li>Club socks</li>
                <li>Club shorts</li>
                <li>Football boots</li>
                <li>Mouthguard</li>
            </ul>
            <p>
                Each season players are usually offered a piece of free kit such as socks,
                shorts, or club t-shirt with registration.
            </p>
            <p>
                On field Jerseys are provided on loan each season and are required to be
                returned – this is to help keep our club going.
            </p>
            <p>
                Parents, carers, and guardians will be required to purchase some on-field kit to
                offset what can be provided with registration. This changes each season
                depending on what sponsorship our club has been given. On-field kit can be
                purchased through our registration process.
            </p>
            <h3>When is junior footy season?</h3>
            <p>
                The competition usually runs from April through August each year. Parents,
                carers, and guardians are able to register their kids to play from November the
                year prior to the season.
            </p>
            <p>
                Training usually starts in February however players can join/register anytime
                until such time as the team is full. It is recommended to register early as
                teams need to be entered by March. Don't wait to register as this makes it hard
                to enter a team and kids may miss out!
            </p>
            <br />
            <p>
                Want more info? Visit <a
                    href="https://play.afl/play/local-footy/junior-footy"
                    target="_blank"
                    rel="noreferrer"
                >Junior Footy - Play AFL</a>
            </p>
            <br />
            <p id="register">
                Port Power welcomes anyone who is interested to come to a training session and
                see what it's all about.
            </p>
            <p>We hope to see you there!</p>
            <a
                href="https://www.playhq.com/afl/register/64ba56"
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    className="port-stephens-afc-button"
                    text="Register Now"
                    rightIcon="chevron-right"
                />
            </a>
        </PageSection>
    );
}

function SchoolFootball() {
    return (
        <PageSection
            id="school-football"
            title="School Football"
        >
            <p>
                Public and non-government primary schools, high schools, and colleges have AFL
                competition pathways each school year. Seek further information from the
                websites below and your school directly.
            </p>
            <h3>NSW Public Government School AFL Competitions</h3>
            <p>
                <a
                    href="https://app.education.nsw.gov.au/sport/"
                    target="_blank"
                    rel="noreferrer"
                >
                    School Sport Unit: School sport (nsw.gov.au)
                </a>
            </p>
            <p>
                <a
                    href="https://aflnswact.com.au/schoolcompetitions/"
                    target="_blank"
                    rel="noreferrer"
                >
                    2024 SCHOOL COMPETITIONS - AFL NSW / ACT (aflnswact.com.au)
                </a>
            </p>
            <h3>NSW Non-Government Schools AFL Competitions</h3>
            <p>
                <a
                    href="https://www.cis.nsw.edu.au/sports/afl"
                    target="_blank"
                    rel="noreferrer"
                >
                    AFL - NSW CIS
                </a>
            </p>
            <p>
                <a
                    href="https://csnsw.sport/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Welcome to Catholic Schools Sports Services | CSNSW Sport Portal
                </a>
            </p>
            <br />
            <h3 id="academies">
                AFL Academy Programs
            </h3>
            <p>
                There are numerous AFL academy programs that are conducted in the Hunter and
                Sydney regions. Port Stephens AFL Club Inc members fall under the Hunter and
                Sydney Academy dedicated programs. Port Stephens AFL Club Inc have had
                successful female and male AFL players participate in each of the programs
                listed below.
                
            </p>
            <p>
                We encourage all members to attend the trial sessions and/or apply as the
                experience is worth it, especially for learning and meeting other players
                outside of the local area. Players might be hesitant to apply, but as previous
                attendees have said, "it might make you nervous but that's a good thing because
                it's worth just getting out there and giving it a go. Makes you a better
                player!"
            </p>
            <p>
                Be sure to research early to be aware of registration dates for Hunter and Swans
                Academies as well as the Application date for Hunter Sports High School.
            </p>
            <p>
                <a
                    href="https://hunteracademy.org.au/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Hunter Academy of Sport
                </a>
            </p>
            <p>
                <a
                    href="https://www.sydneyswans.com.au/academy"
                    target="_blank"
                    rel="noreferrer"
                >
                    QBE Sydney Swans Academy
                </a>
            </p>
            <p>
                <a
                    href="https://hunterspt-h.schools.nsw.gov.au/learning-at-our-school/talented-sports-program/sports-programs.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    Hunter Sports High School - Sports Programs
                </a>
            </p>
        </PageSection>
    );
}

function JuniorRepresentativeProgram() {
    return (
        <PageSection
            id="junior-representative-program"
            title="Junior Representative Program"
        >
            <p>
                AFL Hunter Region runs a representative program for both male and female
                athletes, offering teams at Under 13, Under 15, Under 17, Youth Girls 13, Youth
                Girls 15, and Youth Girls 17 age groups. Each season Hunter enters Hunter
                Metropolitan, Hunter Country or Hunter (Combined Metropolitan and Country)
                teams. Port Stephens AFL Club members are aligned to the Hunter Metropolitan
                teams and combined teams when they are entered.
            </p>
            <p>
                Open trials for the Hunter Representative Programs are usually held in May to
                June each year. Open trials are held at a central location. Keep your eye on the
                news page and our socials for updates and further information. Key club point of
                contact is the committee-coaching coordinator.
            </p>
            <p>
                Once teams are selected, the coach and manager determine the most suitable
                location and time for training depending upon the player pool and availability.
                Training is is usually held once a week. Selected players are provided with an
                onfield jersey (on-loan) and a representative polo short (although this can
                change each season). Selected players pay a registration fee, and will need to
                purchase shorts and socks.
            </p>
            <p>
                The representative program is subject to change each season. Historically, the
                representative program has included a Challenge Cup held at Tuggerah and the
                Coffs Harbour Carnival. In 2024, the program held a single carnival at Coffs
                Harbour. Many Port Stephens AFL Club players were selected. We were proud to
                have our players represent in every Hunter Metro team (U13/U15/U17) both males
                and females, as well as an additional U17s males development squad.
            </p>
            <p>
                For more information, visit <a
                    href="https://aflhuntercentralcoast.com.au/junior-representative-football/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Junior Representative Football - AFL Hunter Central Coast
                </a>
            </p>
        </PageSection>
    );
}

function Gallery() {
    const photos = CollectedImages.Juniors.map(image => ({ src: image.image, width: image.width, height: image.height }));
    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const openLightbox = useCallback((_: unknown, { index }: { index: number }) => {
        setCurrentImage(index);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(null);
    };

    return (
        <PageSection
            id="gallery"
            title="Gallery"
        >
            <ReactPhotoGallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {currentImage !== null
                    ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({ source: x.src }))}
                            />
                        </Modal>
                    )
                    : null}
            </ModalGateway>
        </PageSection>
    );
}

export default function Juniors() {
    useEffect(() => {
        document.title = "Juniors | Port Stephens Power AFL";
    });

    return (
        <SectionContainer>
            <PageHeading
                text="Juniors – developing fundamental skills and a love for the game"
                links={[
                    { text: "About Juniors Football", sectionId: "about-juniors-football" },
                    { text: "Register", sectionId: "register" },
                    { text: "School Football", sectionId: "school-football" },
                    { text: "Academies", sectionId: "academies" },
                    { text: "Training Groups", sectionId: "training-groups" },
                    { text: "Junior Representative Program", sectionId: "junior-representative-program" },
                    { text: "Gallery", sectionId: "gallery" },
                ]}
            />
            <Divider />
            <AboutJuniorsFootball />
            <Divider />
            <SchoolFootball />
            <Divider />
            <TrainingGroups />
            <Divider />
            <JuniorRepresentativeProgram />
            <Divider />
            <Gallery />
        </SectionContainer>
    );
};
