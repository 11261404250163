import { Button, Icon } from '@blueprintjs/core';

import images from '../img/index';

import './Components.scss';

export function Divider() {
    return (
        <div className="divider">
            <div className="divider-line divider-line-1" />
            <div className="divider-line divider-line-2" />
            <div className="divider-line divider-line-3" />
        </div>
    );
}

export function SectionContainer({ children }: { children?: React.ReactNode }) {
    return (
        <div
            className="section-container"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${images['banner2']})`
            }}
        >
            <div className="section-container-inner">
                {children}
            </div>
        </div>
    );
}

interface PageHeadingButtonProps {
    text: string;
    sectionId: string;
}

function PageHeadingButton({ text, sectionId }: PageHeadingButtonProps) {
    function scrollToHeading() {
        document.querySelector('html')!.scroll({
            top: document.getElementById(sectionId)!.offsetTop - 100,
            behavior: 'smooth'
        });
    }

    return (
        <Button
            className='port-stephens-afc-button'
            text={text}
            onClick={scrollToHeading}
        />
    );
}

export function PageHeading({ text, links }: { text: string, links?: PageHeadingButtonProps[] }) {
    return (
        <section className="page-heading">
            <h1>{text}</h1>
            <div className="page-heading-buttons-container">
                <div className="page-heading-buttons">
                    {links && links.map(
                        link => <PageHeadingButton key={link.text} {...link} />
                    )}
                </div>
            </div>
        </section>
    );
}

export function PageSection({ children, id, title }: { children?: React.ReactNode, id: string, title?: string }) {
    return (
        <section
            id={id}
            className="page-section"
        >
            {title && <h2>{title}</h2>}
            {children}
        </section>
    );
}

export function MiniDivider() {
    return (
        <div className="mini-divider">
            <div className="divider-line divider-line-1" />
            <div className="divider-line divider-line-2" />
            <div className="divider-line divider-line-3" />
        </div>
    );
}

export interface TrainingGroupCardProps {
    group: string;
    time?: string | null;
    fixtureLink?: string | null;
    additionalInfo?: string | null;
};

function TrainingGroupCard({ group, time, additionalInfo, fixtureLink }: TrainingGroupCardProps) {
    const content = <>
        <h3 className="training-group-card-title">{group}</h3>
        <MiniDivider />
        <div className="training-group-card-information">
            <p><strong>Time: </strong>{time ?? "TBD"}</p>
            {additionalInfo && <p>{additionalInfo}</p>}
        </div>
        <div className="training-group-card-fixture-prompt">
            {fixtureLink
                ? <>
                    Go To Fixture
                    <Icon icon="chevron-right" />
                </>
                : "Fixture Coming Soon"}
        </div>
    </>
    return (
        fixtureLink
            ? <a
                className="training-group-card"
                href={fixtureLink}
                target="_blank"
                rel="noreferrer"
            >
                {content}
            </a>
            : <div className="training-group-card disabled">
                {content}
            </div>
    );
}

interface TrainingGroupCardsProps {
    trainingGroups: TrainingGroupCardProps[]
};

export function TrainingGroupCards({ trainingGroups }: TrainingGroupCardsProps) {
    return (
        <div className="training-groups-cards-container">
            <div className="training-groups-cards">
                {trainingGroups.map(
                    trainingGroup =>
                        <TrainingGroupCard key={trainingGroup.group} {...trainingGroup} />
                )}
            </div>
        </div>
    );
}
