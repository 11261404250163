import image1 from './img/Seniors/2.jpg';
import image2 from './img/Seniors/3.jpg';
import image3 from './img/Seniors/IMG-20240810-WA0003.jpg';
import image4 from './img/Seniors/IMG-20240817-WA0002.jpg';
import image5 from './img/Seniors/IMG-20240817-WA0003.jpg';
import image6 from './img/Seniors/IMG-20240817-WA0004.jpg';
import image7 from './img/Seniors/IMG-20240817-WA0005.jpg';
import image8 from './img/Seniors/IMG-20240817-WA0006.jpg';
import image9 from './img/Juniors/IMG-20240817-WA0001.jpg';
import image10 from './img/Juniors/IMG-20240817-WA0007.jpg';
import image11 from './img/Juniors/IMG-20240817-WA0008.jpg';
import image12 from './img/Juniors/IMG-20240817-WA0009.jpg';
import image13 from './img/Juniors/IMG-20240817-WA0010.jpg';
import image14 from './img/Juniors/IMG-20240817-WA0011.jpg';
import image15 from './img/Juniors/IMG-20240817-WA0012.jpg';
import image16 from './img/Juniors/IMG-20240817-WA0013.jpg';
import image17 from './img/Juniors/IMG-20240817-WA0014.jpg';

const images = {
    Seniors: [
        {
            image: image1,
            width: 2048,
            height: 2048
        },
        {
            image: image2,
            width: 2048,
            height: 1536
        },
        {
            image: image3,
            width: 1536,
            height: 2048
        },
        {
            image: image4,
            width: 1066,
            height: 1600
        },
        {
            image: image5,
            width: 1600,
            height: 1066
        },
        {
            image: image6,
            width: 1066,
            height: 1600
        },
        {
            image: image7,
            width: 1066,
            height: 1599
        },
        {
            image: image8,
            width: 1440,
            height: 1297
        }
    ],
    Juniors: [
        {
            image: image9,
            width: 900,
            height: 1600
        },
        {
            image: image10,
            width: 900,
            height: 1200
        },
        {
            image: image11,
            width: 1600,
            height: 1068
        },
        {
            image: image12,
            width: 1600,
            height: 720
        },
        {
            image: image13,
            width: 1066,
            height: 1600
        },
        {
            image: image14,
            width: 1066,
            height: 1600
        },
        {
            image: image15,
            width: 1200,
            height: 1600
        },
        {
            image: image16,
            width: 1600,
            height: 1066
        },
        {
            image: image17,
            width: 1600,
            height: 1066
        }
    ]
};
export default images;
