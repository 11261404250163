import { useEffect } from "react";

import { Divider, PageHeading, PageSection, SectionContainer, MiniDivider } from '../Components/Components';

import './Coaches.scss';

interface CoachData {
    name: string;
    coachingLevel: string;
    about: string;
    seasonRole: string;
    favouriteTeam: string;
    favouritePlayer: string;
    photo: string;
}; // TODO: Add socials links?

const coachesData: CoachData[] = require('../CoachesData.json');


function CoachCard({ name, coachingLevel, about, seasonRole, favouriteTeam, favouritePlayer, photo }: CoachData) {
    return (
        <div className="coach-card">
            <img
                src={photo}
                alt={name}
            />
            <MiniDivider />
            <div className="coach-card-content">
                <div className="coach-card-name-container">
                    <div className="coach-card-name">{name}</div>
                </div>
                <div className="coach-card-about">{about}</div>
                <div className="coach-card-coach-level">
                    <b>Coaching Level:</b> {coachingLevel}
                </div>
                <div className="coach-card-season-role">
                    <b>Season Role:</b> {seasonRole}
                </div>
                <div className="coach-card-favourite-team">
                    <b>Favourite Team:</b> {favouriteTeam}
                </div>
                <div className="coach-card-favourite-player">
                    <b>Favourite Player:</b> {favouritePlayer}
                </div>
            </div>
        </div>
    );
}

function OurCoaches() {
    return (
        <PageSection
            id="our-coaches"
            title="Our Coaches"
        >
            <div className="our-coaches-cards-container">
                <div className="our-coaches-cards">
                    {coachesData.map(
                        coach =>
                            <CoachCard key={coach.name} {...coach} />
                    )}
                </div>
            </div>
        </PageSection>
    );
}

export default function Coaches() {
    useEffect(() => {
        document.title = "Coaches | Port Stephens Power AFL";
    });

    return (
        <SectionContainer>
            <PageHeading
                text="Coaches – Guiding our players to success"
                links={[{ text: "Our coaches", sectionId: "our-coaches" }]}
            />
            <Divider />
            <OurCoaches />
        </SectionContainer>
    );
};