import { useEffect } from 'react';
import { NonIdealState } from '@blueprintjs/core';

import '@blueprintjs/core/lib/css/blueprint.css';
import './UnderConstruction.scss';

export default function UnderConstruction() {
    useEffect(() => {
        document.title = "Port Stephens Power AFL";
    });

    return (
        <NonIdealState
            description="This page is under construction."
            icon="wrench"
            title="Under Construction"
        />
    )
};