import { HashRouter, Route, Routes } from "react-router-dom";

import About from '../About/About';
import Auskick from "../Auskick/Auskick";
import Coaches from "../Coaches/Coaches";
import Home from '../Home/Home';
import Juniors from "../Juniors/Juniors";
import Layout from '../Layout/Layout';
import Register from '../Register/Register';
import Seniors from "../Seniors/Seniors";
import UnderConstruction from '../UnderConstruction/UnderConstruction';

import './App.scss';
import '@blueprintjs/core/lib/css/blueprint.css';

function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="auskick" element={<Auskick />} />
                    <Route path="juniors" element={<Juniors />} />
                    <Route path="seniors" element={<Seniors />} />
                    <Route path="coaches-test" element={<Coaches />} />
                    <Route path="register" element={<Register />} />
                    <Route path="*" element={<UnderConstruction />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

export default App;
