import { Button } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";
import Carousel, { Modal, ModalGateway as OriginalModelGateway } from "react-images";
import ReactPhotoGallery from "react-photo-gallery";

import { Divider, PageHeading, PageSection, SectionContainer, TrainingGroupCardProps, TrainingGroupCards } from '../Components/Components';

import CollectedImages from '../CollectedImages';

const ModalGateway = OriginalModelGateway as React.ComponentType<{ children?: React.ReactNode }>;

const trainingGroups: TrainingGroupCardProps[] = require('../SeniorsTrainingGroupsData.json');

function TrainingGroups() {
    return (
        <PageSection
            id="training-groups"
            title="Training Groups"
        >
            <TrainingGroupCards trainingGroups={trainingGroups} />
        </PageSection>
    );
}

function AboutSeniorsFootball() {
    return (
        <PageSection
            id="about-seniors-football"
            title="About Seniors Football"
        >
            <h3>What is Seniors AFL?</h3>
            <p>
                This is mature age of entry into AFL competitions but is still fun! For males
                and females aged 16+ years. The AFL Hunter Central Coast manages the Cup and
                Plate competitions. Port Stephens can enter teams in both Cup and Plate
                competitions so be sure to register yourself and encourage your friends to
                register too!
            </p>
            <i>
                Note: The player's minimum age to participate is to be taken as at December 31st
                of the year immediately prior to the competition year. Therefore players must at
                a minimum be turning 17 years in the year they wish to participate in Senior
                AFL.
            </i>
            <h3>Cup Division</h3>
            <p>
                This is the top tier competition in Hunter and Central Coast. It is suited to
                talented players who take their football seriously. The competition also serves
                as a pathway for young players with aspirations of playing Representative or AFL
                level football.
            </p>
            <h3>Plate Division</h3>
            <p>
                Looking to give Australian Football a try for the first time, returning from a
                layoff, or just love playing the game, Plate is for you. There is always the
                serious edge that comes with playing competitive football but Plate generally
                provides a more relaxed and social environment.
            </p>
            <h3>When is Senior footy season?</h3>
            <p>
                The competition season is held over April to September each year with preseason
                training commencing early February. We have various levels of competition, and
                our club encourages all to participate. We understand the commitments of family,
                relationships and work. Port Stephens AFL Club aims to ensure you enjoy the game
                of footy at our club whilst developing the skills of the game and executing
                those on the field.
            </p>
            <h3>What does the Senior program include?</h3>
            <h4>Training</h4>
            <p>
                Each week the teams will have their own training sessions that run for 1 or 1½
                hours at Ferodale Oval, Medowie. The program is subject to change each season
                depending upon field, coach availability and team majority availability.
                Generally teams may train on a Monday, Tuesday, Wednesday, or Thursday night.
            </p>
            <h4>Match Day</h4>
            <p>
                Each Saturday, teams will play a match against another team in their competition
                in Hunter and Central Coast region. Not all clubs field teams in every
                competition. Generally expect that 50% of the matches are played at the home
                ground: Ferodale Oval, Medowie.
            </p>
            <h3>What are the Hunter and Central Coast Clubs?</h3>
            <p>
                Details of the Senior Clubs can be found at <a
                    href="https://aflhuntercentralcoast.com.au/afl-hunter-central-coast-senior-clubs/"
                    target="_blank"
                    rel="noreferrer"
                >
                    AFL Hunter Central Coast Senior clubs - AFL Hunter Central Coast
                </a>
            </p>
            <h3>How long do the matches go for?</h3>
            <p>
                AFL is played in quarters. Quarter times depend upon the age group and
                competition type. There are allocated breaks at quarter-time, half-time, and
                three-quarter-time. The most recent season match timings were:
            </p>
            <table>
                <colgroup>
                    <col span={1} style={{ width: "50%" }} />
                    <col span={1} style={{ width: "50%" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Competition</th>
                        <th>Quarter Duration (x4 for total field time)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Womens Cup</td>
                        <td>25 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Mens Cup</td>
                        <td>20 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Womens Plate</td>
                        <td>18 minute quarters</td>
                    </tr>
                    <tr>
                        <td>Mens Plate</td>
                        <td>22 minute quarters</td>
                    </tr>
                </tbody>
            </table>
            <h3>What kit is provided with registration?</h3>
            <p>To take the field a player needs:</p>
            <ul>
                <li>Club jersey</li>
                <li>Club socks</li>
                <li>Club shorts</li>
                <li>Football boots</li>
                <li>Mouthguard</li>
            </ul>
            <p>
                Each season players are usually offered a piece of free kit such as socks,
                shorts, or club t-shirt with registration.
            </p>
            <p>
                On field Jerseys are provided on loan each season and are required to be
                returned – this is to help keep our club going.
            </p>
            <p>
                Players will be required to purchase some on-field kit to offset what can be
                provided with registration. This changes each season depending on what
                sponsorship our club has been given. On-field kit can be purchased through our
                registration process.
            </p>
            <br />
            <p id="register">
                Port Power welcomes anyone who is interested to come to a training session and
                see what it's all about.
            </p>
            <p>We hope to see you there!</p>
            <a
                href="https://www.playhq.com/afl/register/03ba5e"
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    className="port-stephens-afc-button"
                    text="Register Now"
                    rightIcon="chevron-right"
                />
            </a>
        </PageSection>
    );
}

function SeniorRepresentativeFootball() {
    return (
        <PageSection
            id="senior-representative-football"
            title="Senior Representative Football"
        >
            <h3>What is Senior Representative footy?</h3>
            <p>
                AFL Hunter Central Coast enter representative teams into a combined competition
                each season. Regions from AFL NSW ACT will enter teams joining together to form
                a competition generally held in July each season. Hunter Central Coast usually
                hold a competition with AFL South Coast, AFL North West NSW and AFL South Coast.
                Each season a region will host the representative competition. During 2024, AFL
                Hunter Central Coast hosted the representative competition on July 20th at
                Central Coast.
            </p>
            <p>
                For more information please contact Port Stephens AFL Club Senior Delegate.
            </p>
            <a
                href="https://www.playhq.com/afl/register/03ba5e"
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    className="port-stephens-afc-button"
                    text="Register Senior Representive Football"
                    rightIcon="chevron-right"
                />
            </a>
            <h3>Masters</h3>
            <p>
                This are separate informal AFL Masters competitions that exist in all regions of
                NSW and the ACT – in Sydney, Illawarra, Hunter Coast, Central Coast, North Coast
                and the ACT. The major annual event is the AFL Masters National carnival held
                each September/October at alternating locations around the country. Hunter
                Masters is a club that has a friendly affiliation with Port Stephens AFL Club
                Inc with many parents and seniors playing with the Hunter Masters.
            </p>
            <p>
                For more information visit <a
                    href="https://hunteraflmasters.teamapp.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Hunter Masters
                </a>
            </p>
            <a
                href="https://www.playhq.com/afl/register/03ba5e"
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    className="port-stephens-afc-button"
                    text="Register Senior Masters Football"
                    rightIcon="chevron-right"
                />
            </a>
        </PageSection>
    );
}

function Gallery() {
    const photos = CollectedImages.Seniors.map(image => ({ src: image.image, width: image.width, height: image.height }));
    const [currentImage, setCurrentImage] = useState<number | null>(null);

    const openLightbox = useCallback((_: unknown, { index }: { index: number }) => {
        setCurrentImage(index);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(null);
    };

    return (
        <PageSection
            id="gallery"
            title="Gallery"
        >
            <ReactPhotoGallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {currentImage !== null
                    ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({ source: x.src }))}
                            />
                        </Modal>
                    )
                    : null}
            </ModalGateway>
        </PageSection>
    );
}

export default function Seniors() {
    useEffect(() => {
        document.title = "Seniors | Port Stephens Power AFL";
    });

    return (
        <SectionContainer>
            <PageHeading
                text="Seniors – the next step in the AFL journey"
                links={[
                    { text: "About Seniors Football", sectionId: "about-seniors-football" },
                    { text: "Register", sectionId: "register" },
                    { text: "Training Groups", sectionId: "training-groups" },
                    { text: "Senior Representative Football", sectionId: "senior-representative-football" },
                    { text: "Gallery", sectionId: "gallery" },
                ]}
            />
            <Divider />
            <AboutSeniorsFootball />
            <Divider />
            <TrainingGroups />
            <Divider />
            <SeniorRepresentativeFootball />
            <Divider />
            <Gallery />
        </SectionContainer>
    );
};
