import { Button } from '@blueprintjs/core';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

import { Divider } from '../Components/Components';
import banner from '../img/banner.jpg';
import about from '../img/carousel/about.png';
import coaches from '../img/carousel/coaches.png';
import contact from '../img/carousel/contact.jpg';
import juniors from '../img/carousel/juniors.jpg';
import news from '../img/carousel/news.png';
import officials from '../img/carousel/officials.jpg';
import register from '../img/carousel/register.jpg';
import seniors from '../img/carousel/seniors.jpg';
import curtisandblair from '../img/sponsors/curtisandblair.gif';
import dowlingmedowie from '../img/sponsors/dowlingmedowie.gif';
// import dsp from '../img/sponsors/dsp.gif';
import glenwoodrural from '../img/sponsors/glenwoodrural.gif';
import maitlandtoyota from '../img/sponsors/maitlandtoyota.gif';
import mcdonalds from '../img/sponsors/mcdonalds.gif';
import medowiemx from '../img/sponsors/medowiemx.gif';
import medowiesocial from '../img/sponsors/medowiesocial.gif';
import raywhite from '../img/sponsors/raywhite.gif';
import steelinegrannyflats from '../img/sponsors/steelinegrannyflats.gif';

import './Home.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'leaflet/dist/leaflet.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -34],
});

L.Marker.prototype.options.icon = DefaultIcon;

function Banner() {
    return (
        <>
            <div className="banner">
                <img
                    className="banner-image"
                    src={banner}
                    alt="Banner"
                />
                <div className="banner-text">
                    <h1>Port Stephens AFC</h1>
                    <h2>Unleashing Victory, Igniting Passion:<br />Every Kick Counts!</h2>
                </div>
            </div>
        </>
    );

}

function PageSlideBlurb({ to, text, description }: { to: string, text: string, description: string }) {
    return (
        <div className="blurb">
            <h2>{text}</h2>
            <div className="blurb-divider" />
            <p>{description}</p>
            <Link to={to}>
                <Button
                    className="blurb-link"
                    text={text}
                />
            </Link>
        </div>
    );
}

function PageSlide({ to, text, image, description }: { to: string, text: string, image: string, description: string }) {
    return (
        <div className="page-slide">
            <PageSlideBlurb
                to={to}
                text={text}
                description={description}
            />
            <img
                src={image}
                alt={`${to}-slide`}
            />

        </div>
    );
}

function PageCarousel() {
    const pages = [
        {
            to: "about",
            text: "About",
            image: about,
            description: "Discover the essence of our club. Immerse yourself in our rich history, values, and the relentless pursuit of excellence that defines our identity."
        },
        {
            to: "juniors",
            text: "Juniors",
            image: juniors,
            description: "Dive into the future with our junior members! Fueled by passion, shaped by skill, it's where budding talents kickstart their journey to become the champions of tomorrow."
        },
        {
            to: "seniors",
            text: "Seniors",
            image: seniors,
            description: "Check out our senior members – where experience meets excellence. Join a community of players crafting their legacy on the field, one victory at a time."
        },
        {
            to: "coaches",
            text: "Coaches",
            image: coaches,
            description: "Go to the strategist's realm with our Coaching Corner. Meet the masterminds shaping victories, refining skills, and inspiring players."
        },
        {
            to: "officials",
            text: "Officials",
            image: officials,
            description: "Explore the dedicated individuals navigating the game's pulse, ensuring fair play, and fostering a culture of sportsmanship."
        },
        {
            to: "register",
            text: "Register",
            image: register,
            description: "Secure your spot on the field! Join our winning team by completing your player registration. Be part of the action, the camaraderie, and the pursuit of victory. Let's kickstart your journey with us."
        },
        // TODO: re-add news page when we have a good image
        // {
        //     to: "news",
        //     text: "News",
        //     image: news,
        //     description: "Stay in the know with our club news hub! Check out the latest victories, player spotlights, and behind-the-scenes stories that fuel our passion for the game."
        // },
        {
            to: "contact",
            text: "Contact",
            image: contact,
            description: "Connect with us! Whether you have questions, feedback, or just want to share the excitement of the game, we're here. Reach out and be a part of the conversation."
        }
    ];

    return (
        <Carousel
            autoPlay={true}
            dynamicHeight={false}
            infiniteLoop={true}
            interval={3500}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            stopOnHover={true}
        >
            {pages.map((page: { to: string, text: string, image: string, description: string }) =>
                <PageSlide key={page.to} {...page} />
            )}
        </Carousel>
    );
}

function Card({ title, children }: { title: string, children: React.ReactNode }) {
    return (
        <div className="card">
            <h2>{title}</h2>
            {children}
        </div>
    );
}

function Map() {
    const [initialised, setInitialised] = useState(false);
    useEffect(() => {
        if (!initialised) {
            const loc = new L.LatLng(-32.742778, 151.860424);
            const map = L.map('map').setView(loc, 14);

            // Populate
            L.tileLayer(
                "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
                {
                    maxZoom: 19,
                    attribution: "Map data ©2015 Google"
                }
            ).addTo(map);
            L.marker(loc).addTo(map);

            // Return to club
            new (
                L.Control.extend({
                    options: {
                        position: 'topleft'
                    },
                    onAdd: function (map: L.Map) {
                        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                        var button = L.DomUtil.create('a', 'leaflet-control-button', container);
                        button.innerHTML = `<svg data-icon="map-marker" height="18" role="img" viewBox="0 0 20 20" width="18"><path d="M9.98 0c-3.87 0-7 2.98-7 6.67 0 3.68 7 13.33 7 13.33s7-9.65 7-13.33c0-3.68-3.14-6.67-7-6.67zm0 10c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" fill-rule="evenodd"></path></svg>`;
                        L.DomEvent.disableClickPropagation(button);
                        L.DomEvent.on(button, 'click', function() {
                            map.setView(loc, 14);
                        });
                
                        container.title = "Return to club";
                
                        return container;
                    }
                })
            )().addTo(map);

            // Get directions
            new (
                L.Control.extend({
                    options: {
                        position: 'topleft'
                    },
                    onAdd: function () {
                        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                        var button = L.DomUtil.create('a', 'leaflet-control-button', container);
                        button.innerHTML = `<svg data-icon="path-search" height="18" role="img" viewBox="0 0 20 20" width="18"><path d="M4 7c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm15 11.69l-5-2.5v-3.63c-.32.11-.66.22-1 .29v3.32l-6 2.57v-7.25c-.36-.27-.69-.57-1-.9v8.1l-5-2.5V10c.55 0 1-.45 1-1s-.45-1-1-1V1.31l3.43 1.71c.11-.31.24-.62.39-.92L.72.05A.545.545 0 00.5 0C.22 0 0 .22 0 .5v16c0 .2.12.36.28.44l6 3c.07.04.14.06.22.06.07 0 .14-.01.2-.04l6.79-2.91 5.79 2.9c.07.03.14.05.22.05.28 0 .5-.22.5-.5v-4.21c-.31.13-.64.21-1 .21v3.19zM10 5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3-1c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm6.72-.94l-1.43-.72c.2.43.36.89.48 1.36l.23.11V5.5c-.55 0-1 .45-1 1s.45 1 1 1v1.96l1 1V3.5c0-.2-.12-.36-.28-.44zm-3.69 5.56c.14-.21.27-.42.38-.65.02-.04.04-.07.05-.11.11-.22.2-.45.28-.69v-.01c.07-.24.13-.48.17-.73l.03-.17c.04-.25.06-.5.06-.76C17 2.46 14.54 0 11.5 0S6 2.46 6 5.5 8.46 11 11.5 11c.26 0 .51-.02.76-.06l.17-.03c.25-.04.49-.1.73-.17h.01c.24-.08.47-.17.69-.28.04-.02.07-.03.11-.05.23-.11.44-.24.65-.38l.18.18 3.5 3.5c.17.18.42.29.7.29a1.003 1.003 0 00.71-1.71l-3.68-3.67zm-4.53.88c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" fill-rule="evenodd"></path></svg>`;
                        L.DomEvent.disableClickPropagation(button);
                        button.href = "https://www.google.com/maps/dir//PORT+STEPHENS+AUSTRALIAN+FOOTBALL+CLUB+INC,+Ferodale+Sports+Complex,+36+Ferodale+Rd,+Medowie+NSW+2318,+Australia/@-32.742778,151.860424,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x6b736f926e38ec6b:0xd3aea9c7a4509eed!2m2!1d151.8604242!2d-32.7427777!3e0?hl=en-GB&entry=ttu";
                        button.target = "_blank";
                        button.rel = "noreferrer";
                
                        container.title = "Get directions";
                
                        return container;
                    }
                })
            )().addTo(map);

            // React to resize
            window.addEventListener("resize", () => map.invalidateSize());
            setInitialised(true);
        }
    }, [initialised]);

    return (
        <div id="map" />
    );
}

function Sponsors() {
    const sponsors = [
        {
            image: glenwoodrural,
            hyperlink: "https://glenwoodrural.com.au/",
            name: "glenwoodrural"
        },
        {
            image: steelinegrannyflats,
            hyperlink: "https://www.steelinegrannyflats.com.au/",
            name: "steelinegrannyflats"
        },
        {
            image: mcdonalds,
            hyperlink: "https://mcdonalds.com.au",
            name: "mcdonalds"
        },
        {
            image: curtisandblair,
            hyperlink: "https://curtisandblair.com.au/",
            name: "curtisandblair"
        },
        {
            image: dowlingmedowie,
            hyperlink: "https://dowlingmedowie.com.au/",
            name: "dowlingmedowie"
        },
        // {
        //     image: dsp,
        //     hyperlink: "https://dsp.net.au/",
        //     name: "dsp"
        // },
        // {
        //     image: medowiesocial,
        //     hyperlink: "https://medowiesocial.com.au/",
        //     name: "medowiesocial"
        // },
        {
            image: raywhite,
            hyperlink: "https://raywhitemrt.com.au/about/ray-white-medowie/3454",
            name: "raywhite"
        },
        {
            image: medowiemx,
            hyperlink: "https://medowiemx.com.au/",
            name: "medowiemx"
        },
        {
            image: maitlandtoyota,
            hyperlink: "https://maitlandtoyota.com.au/",
            name: "maitlandtoyota"
        }
    ];

    return (
        <>
            <Carousel
                autoPlay={true}
                dynamicHeight={false}
                infiniteLoop={true}
                interval={16000}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                stopOnHover={true}
            >
                {sponsors.map((sponsor: { image: string, hyperlink: string, name: string }) =>
                    <a
                        key={sponsor.name}
                        className="sponsor"
                        href={sponsor.hyperlink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={sponsor.image}
                            alt={sponsor.name}
                        />
                    </a>
                )}
            </Carousel>
            <p>
                Port Stephens Power Australian Football Club sends a huge thanks out to our sponsors for 2024! Without you we would not succeed. Those in the community who wish to visit the sponsors websites you can access them via the banner above. Once again thanks from the entire committee!
            </p>
        </>
    );
}

export default function Home() {
    useEffect(() => {
        document.title = "Home | Port Stephens Power AFL";
    });

    return (
        <>
            <Banner />
            <Divider />
            <PageCarousel />
            <Divider />
            <div className="cards">
                <Card title="Location">
                    <Map />
                </Card>
                <Card title="Sponsors">
                    <Sponsors />
                </Card>
            </div>
        </>
    );
};