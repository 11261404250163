import { Button } from '@blueprintjs/core';
import { useEffect } from 'react';

import './Register.scss';
import '@blueprintjs/core/lib/css/blueprint.css';

function RegisterButton({ url, text }: { url: string, text: string}) {
    return (
        <a
            href={url}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                className="register-button"
                text={text}
                rightIcon="chevron-right"
                fill={true}
                alignText="left"
            />
        </a>
    );
}

export default function Register() {
    useEffect(() => {
        document.title = "Register | Port Stephens Power AFL";
    });

    return (
        <>
            <div className="register">
                <h1>Register Now!</h1>
                <p>Registration for the 2024 season is now open. Click below to register now!</p>
                <div className="register-buttons">
                    <RegisterButton
                        url="https://www.playhq.com/afl/register/973748"
                        text="Auskick"
                    />
                    <RegisterButton
                        url="https://www.playhq.com/afl/register/64ba56"
                        text="Juniors"
                    />
                    <RegisterButton
                        url="https://www.playhq.com/afl/register/03ba5e"
                        text="Seniors (Women)"
                    />
                    <RegisterButton
                        url="https://www.playhq.com/afl/register/03ba5e"
                        text="Seniors (Men)"
                    />
                </div>
            </div>
        </>
    );
};