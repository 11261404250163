import { useEffect } from 'react';

import { Divider, PageHeading, PageSection, SectionContainer } from '../Components/Components';
import images from '../img/index';

import './About.scss';

interface ProfileCardData {
    name: string;
    description: string;
    startYear: number;
    endYear?: number;
    image?: string;
};

interface ClubVolunteerOfTheYearData {
    name: string;
    year: number;
}

const lifeMembersData: ProfileCardData[] = require('../LifeMembersData.json');
const clubVolunteerOfTheYearData: ClubVolunteerOfTheYearData[] = require('../ClubVolunteerOfTheYearData.json');

function ClubHistory() {
    return (
        <PageSection
            id="club-history"
            title="Club History"
        >
            <h3>Foundation</h3>
            <p>The club started off in April 1998 as Williamtown Auskick Centre and was run at the RAAF base by dedicated parents keen to give their children the opportunity to learn and play AFL football. It proved successful with 23 Auskickers. Mid 1999 the Centre was approached by the Newcastle Junior AFL to enter a team in the under 10’s as a trial. It proved successful however due to problems in obtaining passes to the base the decision to move to Kindlebark Oval was taken.</p>
            <p>The continued success of the teams led to a public meeting on Thursday 26th August 1999 chaired by Mr Barry Alexander (Local area AFL Representative) with a view to forming the Port Stephens Junior Australian Football club. As result of the meeting a committee was appointed and the AFLJFC formed. Following recommendations from the local community and council, the colours of Port Adelaide AFL were adopted and the name the club became Port Power.</p>
            <p>Ian Matthews was elected as the first President.</p>
            <h3>Early History</h3>
            <p>In the year 2000, we fielded an U10 and an U12 team playing out of Kindlebark Oval and in 2001 we started playing at the new Ferodale Oval as we now had an U14 team which required a full field. The Council assisted with welding the goal posts but installation and painting etc was undertaken by the then Grounds Equipment manager, Noel Marriott.</p>
            <p>For the first couple of years the club used a converted farmhouse, where the netball courts are now, for canteen and change rooms until the new clubhouse was built and opened 20 August 2004.</p>
            <p>Auskick continued to train and play at Kindlebark for a few more years until the lights were installed circa 2005.</p>
            <figure>
                <img
                    src={images['october2003']}
                    alt="Club photo, circa October 2003"
                />
                <figcaption>Club photo, circa October 2003</figcaption>
            </figure>
            <h3>Modern Era</h3>
            <p>Our recruiting area encompasses a large area of Port Stephens including Fern Bay, Hawkes Nest, Karuah, Lemon Tree Passage, Medowie, Raymond Terrace, Salt Ash, Seaham, Stockton, Tanilba Bay, Tea Gardens and Williamtown.</p>
            <p>In 2017 the then committee agreed that we had the numbers to submit an application to join the Ladies competition. This was accepted by the League and the ladies played their first game in 2018.</p>
            <p>To reflect our development as a club, the name was changed to Port Stephens Australian Football Club on 4th October 2017.</p>
        </PageSection>
    );
}

function ProfileCard({ name, description, startYear, endYear, image }: ProfileCardData) {

    return (
        <div className="profile-card">
            <div className="profile-card-content">
                <h3>{name} ({startYear}-{endYear})</h3>
                <p>{description}</p>
            </div>
            {image && 
                <div className="profile-card-image">
                    <img src={images[image]} alt={image} />
                </div>}
        </div>
    );
}

function LifeMembers() {
    return (
        <PageSection
            id="life-members"
            title="Life Members"
        >
            <p>
                Port Stephens Power AFL is proud of its life members. These are the people who
                have made a significant contribution to the club over the years. We thank them
                for their dedication and hard work.
            </p>
            {lifeMembersData.map(
                member =>
                    <ProfileCard key={member.name} {...member} />
            )}
        </PageSection>
    );
}

function ClubVolunteerOfTheYear() {
    return (
        <PageSection
            id="club-volunteer-of-the-year"
            title="Club Volunteer of the Year"
        >
            <p>
                Each season, members are able to nominate other club member volunteers as the
                Club Volunteer of the year. Of those nominees, members are chosen for their
                contribution to the club and awarded club person of the year. The award 
                ecognises the hard work and dedication of our volunteers.
            </p>
            <ul>
                {clubVolunteerOfTheYearData.map(
                    volunteer =>
                        <li key={volunteer.year}>
                            <b>{volunteer.year}:</b> {volunteer.name}
                        </li>
                )}
            </ul>
        </PageSection>
    );
}

export default function About() {
    useEffect(() => {
        document.title = "About | Port Stephens Power AFL";
    });

    return (
        <SectionContainer>
            <PageHeading
                text="About our club – the history of the people that brought us here today"
                links={[
                    { text: "Club History", sectionId: "club-history" },
                    { text: "Life Members", sectionId: "life-members" },
                    { text: "Club Volunteer of the Year", sectionId: "club-volunteer-of-the-year" },
                ]}
            />
            <Divider />
            <ClubHistory />
            <Divider />
            <LifeMembers />
            <Divider />
            <ClubVolunteerOfTheYear />
        </SectionContainer>
    );
};
